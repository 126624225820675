import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import Cookies from 'js-cookie';
import './navigation';
import './ajax-load-more';
/**
 * Homepage notice
 */

var notice = document.querySelector('.home .notice');

if (notice) {
  var closeBtn = notice.querySelector('.btn-close');
  closeBtn.addEventListener('click', function () {
    Cookies.set('tolle_hide_notice', true);
  });
}
/**
 * Swiper
 */


jQuery(document).ready(function () {
  var style = document.createElement('style');

  var onMouseUpdate = function onMouseUpdate(e) {
    jQuery('.mouse-position').css('top', e.pageY + 'px');
    jQuery('.mouse-position').css('left', e.pageX + 'px');
  };

  var swiper = new Swiper('.swiper', {
    speed: 500,
    slidesPerView: 1,
    spaceBetween: 50,
    freeMode: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets'
    },
    breakpoints: {
      1025: {
        slidesPerView: 2
      }
    }
  });
  jQuery('.mouse-position').css('position', 'absolute');
  swiper.on('sliderMove', function (e) {
    jQuery('.mouse-position').css('top', e.touches.currentY + 'px');
    jQuery('.mouse-position').css('left', e.touches.currentX + 'px');
  }); //document.body.appendChild(style)

  document.addEventListener('mousemove', onMouseUpdate, false);
});
/**
 * Focus Within When Field are filled
 */

jQuery(document).on('gform_post_render', function (event, form_id, current_page) {
  jQuery('#gform_fields_' + form_id + ' input, #gform_fields_' + form_id + ' textarea').each(function () {
    var $this = jQuery(this);
    var gfield = $this.parents('.gfield');
    $this.on('input, change', function () {
      if ($this.val()) {
        gfield.addClass('focus-within');
      } else {
        gfield.removeClass('focus-within');
      }

      var upload_file_parent_wrapper = $this.parents('.gform_upload_file');

      if (upload_file_parent_wrapper.length) {
        var info_target = upload_file_parent_wrapper.find('.gform_fileupload_rules');
        info_target.text($this.val().split('\\').pop());
        info_target.show();
      }
    });
    $this.trigger('change');
  });
});
/**
 * JS CORDEON
 */

jQuery(document).ready(function () {
  var cordeons = jQuery('.js-cordeon');
  cordeons.each(function () {
    var cordeon = jQuery(this);
    cordeon.click(function () {
      if (cordeon.hasClass('is-open')) {
        cordeon.removeClass('is-open');
      } else {
        cordeons.removeClass('is-open');
        cordeon.addClass('is-open');
      }
    });
  });
});
/**
 * Main menu
 */

jQuery(document).ready(function () {
  ;

  (function () {
    var body = document.querySelector('body');
    var burger = document.querySelector('.js-burger');

    if (!burger) {
      return;
    }

    burger.addEventListener('click', function () {
      body.classList.toggle('menu-is-active');
    });
  })();

  (function () {
    jQuery('.menu-item-has-children').click(function (e) {
      var xNorm = e.clientX / window.innerWidth;

      if (xNorm > 0.75 && window.innerWidth < 1024) {
        e.preventDefault();
        e.stopPropagation();
        jQuery(this).toggleClass('menu-item-has-children--open');
      }
    });
  })();
});