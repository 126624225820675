import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.promise.js";
window.addEventListener('DOMContentLoaded', function () {
  var postList = document.querySelector('.posts-list');

  if (postList) {
    var button = document.querySelector('#posts-load-more');

    if (typeof button != 'undefined' && button != null) {
      button.addEventListener('click', function (e) {
        var buttonText = button.innerHTML;
        var postsList = document.querySelector('.posts-list');
        button.innerHTML = button.dataset.loading;
        button.disabled = true;
        var currentPage = postsList.dataset.page;
        var postType = postsList.dataset.postType;
        var postsPerPage = postList.dataset.postsPerPage;
        var taxonomy = postsList.dataset.taxonomy;
        var termTaxonomyId = postsList.dataset.termTaxonomyId;
        var search = postsList.dataset.search;
        var documentCat = postsList.dataset.documentCat;
        var questionCat = postsList.dataset.questionCat;
        var url = '/wp-admin/admin-ajax.php';
        var params = new URLSearchParams();
        params.append('action', document.body.classList.contains('search') ? 'load_more_search' : 'load_more_posts');
        params.append('current_page', currentPage);
        params.append('post_type', postType);

        if (postsPerPage) {
          params.append('posts_per_page', postsPerPage);
        }

        if (taxonomy && termTaxonomyId) {
          params.append('taxonomy', taxonomy);
          params.append('term_taxonomy_id', termTaxonomyId);
        }

        if (search) {
          params.append('search', search);
        }

        if (documentCat) {
          params.append('document_cat', documentCat);
        }

        if (questionCat) {
          params.append('question_cat', questionCat);
        }

        fetch(url, {
          method: 'POST',
          body: params
        }).then(function (response) {
          return response.json();
        }).then(function (_ref) {
          var data = _ref.data;
          button.disabled = false;
          button.innerHTML = buttonText;
          var postsCount = document.querySelector('#posts-load-more-count');
          postList.insertAdjacentHTML('beforeend', data);
          postList.dataset.page++;

          if (postsCount) {
            postsCount.innerHTML = postList.querySelectorAll('article').length;
          }

          if (postList.dataset.page == postList.dataset.max) {
            button.parentNode.removeChild(button);
          }
        });
      });
    }
  }
});